import { getVehicleOptions } from '../api';
import _ from 'lodash';

export const GET_VEHICLE_OPTIONS_REQUEST = 'GET_VEHICLE_OPTIONS_REQUEST';
export const GET_VEHICLE_OPTIONS_SUCCESS = 'GET_VEHICLE_OPTIONS_SUCCESS';
export const GET_VEHICLE_OPTIONS_ERROR = 'GET_VEHICLE_OPTIONS_ERROR';

type initialStateType = {
  isLoading: boolean;
  error: any;
  brands: any[];
  models: any[];
  fuels: any[];
};
export const initialState: initialStateType = {
  isLoading: false,
  error: null,
  brands: [],
  models: [],
  fuels: [],
};

/**
 * Reducer
 */
export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_VEHICLE_OPTIONS_REQUEST:
      return Object.assign({}, state, {
        error: null,
        isLoading: true,
      });
    case GET_VEHICLE_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        brands: action.payload.brands,
        models: action.payload.models,
        fuels: action.payload.fuels,
      });
    case GET_VEHICLE_OPTIONS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.payload,
      });
    default:
      return state;
  }
};

/**
 * Action creators
 */
// Fetch vehicles
export const getVehicleOptionsRequest = () => {
  return (dispatch: any) => {
    dispatch({ type: GET_VEHICLE_OPTIONS_REQUEST });
    return getVehicleOptions()
      .then((response) => {
        // console.log('Get Vehicles response', response.data);
        dispatch(getVehicleOptionsSuccess(response.data));
      })
      .catch((error) => {
        if (error.response)
          switch (error.response.status) {
            case 400:
              dispatch(getVehicleOptionsError('BAD_REQUEST'));
              break;
            default:
              dispatch(getVehicleOptionsError('SERVER_ERROR'));
          }
        else dispatch(getVehicleOptionsError('SERVER_ERROR'));
      });
  };
};

// Vehicles fetched successfully
export const getVehicleOptionsSuccess = (vehicles: unknown) => {
  return {
    type: GET_VEHICLE_OPTIONS_SUCCESS,
    payload: vehicles,
  };
};

// Error fetching vehicles
export const getVehicleOptionsError = (error: unknown) => {
  return {
    type: GET_VEHICLE_OPTIONS_ERROR,
    payload: error,
  };
};

/**
 * Selectors
 */
export const brandsSelector = (state: any) => [
  { code: 'AUDI', id: -41, name: 'Audi' },
  { code: 'BMW', id: -86, name: 'BMW' },
  { code: 'DACIA', id: -181, name: 'Dacia' },
  { code: 'FIAT', id: -67, name: 'Fiat' },
  { code: 'HYUNDAI', id: -147, name: 'Hyundai' },
  { code: 'MERCEDES-BENZ', id: -138, name: 'Mercedes-Benz' },
  { code: 'NISSAN', id: -4, name: 'Nissan' },
  { code: 'OPEL', id: -19, name: 'Opel' },
  { code: 'PEUGEOT', id: -49, name: 'Peugeot' },
  { code: 'RENAULT', id: -99, name: 'Renault' },
  { code: 'SKODA', id: -154, name: 'Skoda' },
  { code: 'TOYOTA', id: -40, name: 'Toyota' },
  { code: 'VOLKSWAGEN', id: -80, name: 'Volkswagen' },
  ..._.sortBy(state.vehicle.brands, 'name'),
];

export const modelsSelector = (state: any) => _.sortBy(state.vehicle.models, 'name');
export const fuelsSelector = (state: any) => _.sortBy(state.vehicle.fuels, 'name');
