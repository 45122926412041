import { getPickerRamps } from '../api';
import _ from 'lodash';
import { setPickerRampFilter } from './search';

export const GET_PICKER_RAMPS_REQUEST = 'GET_PICKER_RAMPS_REQUEST';
export const GET_PICKER_RAMPS_SUCCESS = 'GET_PICKER_RAMPS_SUCCESS';
export const GET_PICKER_RAMPS_ERROR = 'GET_PICKER_RAMPS_ERROR';

export const initialState = {
  isLoading: false,
  error: null,
  pickerRamps: [],
};

/**
 * Reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PICKER_RAMPS_REQUEST:
      return Object.assign({}, state, {
        error: null,
        isLoading: true,
      });
    case GET_PICKER_RAMPS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        pickerRamps: action.payload,
      });
    case GET_PICKER_RAMPS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.payload,
      });
    default:
      return state;
  }
};

/**
 * Action creators
 */
// Fetch pickerRamps
export const getPickerRampsRequest = () => {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch({ type: GET_PICKER_RAMPS_REQUEST });
    return getPickerRamps(auth.service)
      .then((response) => {
        // console.log('Get picker ramps response', response.data);
        dispatch(getPickerRampsSuccess(response.data));

        const pickerRampFilter = _.head(response.data);
        if (pickerRampFilter) {
          dispatch(setPickerRampFilter(pickerRampFilter.id));
        }
      })
      .catch((error) => {
        if (error.response)
          switch (error.response.status) {
            case 400:
              dispatch(getPickerRampsError('BAD_REQUEST'));
              break;
            default:
              dispatch(getPickerRampsError('SERVER_ERROR'));
          }
        else dispatch(getPickerRampsError('SERVER_ERROR'));
      });
  };
};

// Picker ramps fetched successfully
export const getPickerRampsSuccess = (pickerRamps: any) => {
  return {
    type: GET_PICKER_RAMPS_SUCCESS,
    payload: pickerRamps,
  };
};

// Error fetching pickerRamps
export const getPickerRampsError = (error) => {
  return {
    type: GET_PICKER_RAMPS_ERROR,
    payload: error,
  };
};

/**
 * Selectors
 */
export const pickerRampsSelector = (state) => state.pickerRamp.pickerRamps;
