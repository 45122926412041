import React from 'react';
import { UIMemoWrapper } from './UIMemoWrapper';
import z from 'zod';
import { Alert } from 'antd';

const AlertSchema = z.object({
  type: z.enum(['error', 'warning', 'success', 'info']).optional(),
  title: z.string().optional(),
  message: z.string().optional(),
});
export type Alert = z.infer<typeof AlertSchema>;

const initialState = {
  showAlert: (_?: Alert) => {
    // empty
  },
};

export const AlertContext = React.createContext(initialState);

const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [alert, setAlert] = React.useState<Alert>(null);
  const timeoutRef = React.useRef<any>(); // !! TODO @razvan fix this any with the return type of timeout
  const showAlert = React.useCallback(
    (alert?: Alert) => {
      const alertObject = AlertSchema.safeParse(alert || {});

      if (alertObject.success) {
        setAlert(alertObject.data);

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          setAlert(null);
        }, 5 * 1000);
      }
    },
    [setAlert]
  );

  const value = React.useMemo(
    () => ({
      showAlert,
    }),
    [showAlert]
  );

  return (
    <AlertContext.Provider value={value}>
      <UIMemoWrapper>{children}</UIMemoWrapper>
      {alert && (
        <div className="fixed top-0 left-0 right-0 z-50">
          <Alert
            message={alert.title || 'Oops!!'}
            description={alert.message || 'A aparut o eroare. Daca problema persista te rog sa ne contactezi.'}
            type={alert.type || 'error'}
            closable
            onClose={() => {
              showAlert(null);
            }}
          />
        </div>
      )}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
