import { getServiceRamps } from '../api';
import _ from 'lodash';
import { setServiceRampFilter } from './search';

export const GET_SERVICE_RAMPS_REQUEST = 'GET_SERVICE_RAMPS_REQUEST';
export const GET_SERVICE_RAMPS_SUCCESS = 'GET_SERVICE_RAMPS_SUCCESS';
export const GET_SERVICE_RAMPS_ERROR = 'GET_SERVICE_RAMPS_ERROR';

export const initialState = {
  isLoading: false,
  error: null,
  serviceRamps: [],
};

/**
 * Reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICE_RAMPS_REQUEST:
      return Object.assign({}, state, {
        error: null,
        isLoading: true,
      });
    case GET_SERVICE_RAMPS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        serviceRamps: action.payload,
      });
    case GET_SERVICE_RAMPS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.payload,
      });
    default:
      return state;
  }
};

/**
 * Action creators
 */
// Fetch serviceRamps
export const getServiceRampsRequest = () => {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch({ type: GET_SERVICE_RAMPS_REQUEST });
    return getServiceRamps(auth.service)
      .then((response) => {
        // console.log('Get service ramps response', response.data);
        dispatch(getServiceRampsSuccess(response.data));

        const serviceRampFilter = _.head(response.data);
        if (serviceRampFilter) {
          dispatch(setServiceRampFilter(serviceRampFilter.id));
        }
      })
      .catch((error) => {
        if (error.response)
          switch (error.response.status) {
            case 400:
              dispatch(getServiceRampsError('BAD_REQUEST'));
              break;
            default:
              dispatch(getServiceRampsError('SERVER_ERROR'));
          }
        else dispatch(getServiceRampsError('SERVER_ERROR'));
      });
  };
};

// Service ramps fetched successfully
export const getServiceRampsSuccess = (serviceRamps: any) => {
  return {
    type: GET_SERVICE_RAMPS_SUCCESS,
    payload: serviceRamps,
  };
};

// Error fetching serviceRamps
export const getServiceRampsError = (error) => {
  return {
    type: GET_SERVICE_RAMPS_ERROR,
    payload: error,
  };
};

/**
 * Selectors
 */
export const serviceRampsSelector = (state) => state.serviceRamp.serviceRamps;
