import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import qs from 'qs';
import React, { PureComponent } from 'react';
import { getOrderStatus } from '../../../api';
import './styles.css';

class PaymentMobileReturnPage extends PureComponent {
  state = {
    isLoading: true,
    isSuccess: false,
    message: null,
    returnUrl: null,
  };

  componentDidMount() {
    const { location } = this.props;

    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { orderId } = params;

    console.log('Order ID', orderId);

    this.fetchOrder(orderId);
  }

  /**
   * Fetch order information
   */
  fetchOrder = (orderId, nTry = 0) => {
    console.log(`Fetching order ${orderId} info. (nTry=${nTry})`);
    getOrderStatus(orderId)
      .then((result) => {
        console.log('Order', result.data);
        const { message, isSuccess } = result.data;

        this.setState({ message, isSuccess, isLoading: false, returnUrl: '/payment/return/finished/' });
      })
      .catch((err) => {
        console.error(err);

        if (nTry < 7) {
          // Retry
          console.log('Retrying...');
          setTimeout(() => {
            this.fetchOrder(orderId, nTry + 1);
          }, 2500);
        } else {
          // Number of maximum retries exceeded
          console.warn('Number of maximum retries exceeded.');
          window.location = '/payment/return/finished/';
        }
      });
  };

  handleGoBack = () => {
    const { returnUrl } = this.state;

    if (!returnUrl) {
      return;
    }

    // history.push(returnUrl);
    window.location = returnUrl;
  };

  render() {
    const { isLoading, message, returnUrl, isSuccess } = this.state;

    return (
      <div className="payment__loading-container">
        <div className="payment__loading-indicator">
          {isLoading && <Spin spinning={true} size={'large'}></Spin>}
          <div className="payment__loading-content">
            {isSuccess && <CheckCircleOutlined className="payment__loading-icon" />}
            {message && <p className="payment__loading-message">{message}</p>}
            {returnUrl && (
              <Button type="primary" onClick={this.handleGoBack} size="large">
                Mergi înapoi
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentMobileReturnPage;
