import { getBlockRamps } from '../api';
import _ from 'lodash';

export const GET_BLOCK_RAMPS_REQUEST = 'GET_BLOCK_RAMPS_REQUEST';
export const GET_BLOCK_RAMPS_SUCCESS = 'GET_BLOCK_RAMPS_SUCCESS';
export const GET_BLOCK_RAMPS_ERROR = 'GET_BLOCK_RAMPS_ERROR';

export const initialState = {
  isLoading: false,
  error: null,
  blockRamps: [],
};

/**
 * Reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOCK_RAMPS_REQUEST:
      return Object.assign({}, state, {
        error: null,
        isLoading: true,
      });
    case GET_BLOCK_RAMPS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        blockRamps: action.payload,
      });
    case GET_BLOCK_RAMPS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.payload,
      });
    default:
      return state;
  }
};

/**
 * Action creators
 */
// Fetch operations
export const getBlockRampsRequest = () => {
  return (dispatch) => {
    dispatch({ type: GET_BLOCK_RAMPS_REQUEST });
    return getBlockRamps()
      .then((response) => {
        console.log('Get Blockramp response', response.data);
        //dispatch(getBlockRampsSuccess(response.data));
        const sortedData = _.take(_.orderBy(response.data, ['date'], ['desc']), 200); //pulled from nothing
        dispatch(getBlockRampsSuccess(sortedData));
      })
      .catch((error) => {
        if (error.response)
          switch (error.response.status) {
            case 400:
              dispatch(getBlockRampsError('BAD_REQUEST'));
              break;
            default:
              dispatch(getBlockRampsError('SERVER_ERROR'));
          }
        else dispatch(getBlockRampsError('SERVER_ERROR'));
      });
  };
};

// Blockramps fetched successfully
export const getBlockRampsSuccess = (blockRamps) => {
  return {
    type: GET_BLOCK_RAMPS_SUCCESS,
    payload: blockRamps,
  };
};

// Error fetching operations
export const getBlockRampsError = (error) => {
  return {
    type: GET_BLOCK_RAMPS_ERROR,
    payload: error,
  };
};

/**
 * Selectors
 */
export const blockRampsSelector = (state) => state.blockRamp.blockRamps;
