import React, { PureComponent } from 'react';
import { Spin } from 'antd';
import './styles.css';

class PaymentMobileFinishedPage extends PureComponent {
  render() {
    return (
      <div className="payment__loading-container">
        <div className="payment__loading-indicator">
          <Spin spinning={true} size={'large'}></Spin>
        </div>
      </div>
    );
  }
}

export default PaymentMobileFinishedPage;
