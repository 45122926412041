import z, { ZodAny } from 'zod';

export const BrandSchema = z.object({
  id: z.number(),
  name: z.string(),
  code: z.string().nullable().optional(),
  image: z.string().nullable().optional(),
});
export const BrandsSchema = z.array(BrandSchema).nullable();
export type Brand = z.infer<typeof BrandSchema>;
export const ModelSchema = z.object({
  id: z.number(),
  name: z.string(),
  code: z.string(),
  brand: z.number(),
});
export type Model = z.infer<typeof ModelSchema>;
export const FuelSchema = z.object({
  id: z.number(),
  name: z.string(),
});
export type Fuel = z.infer<typeof FuelSchema>;

const UserSchema = z.object({
  address: z.string().nullable(),
  createdAt: z.string(),
  creditsCode: z.string().nullable(),
  deletedAt: z.string().nullable(),
  description: z.string().nullable(),
  email: z.string(),
  enabled: z.boolean(),
  fname: z.string(),
  hasRated: z.boolean(),
  id: z.number(),
  lname: z.string(),
  mgc: z.string().nullable(),
  phone: z.string().nullable(),
  photo: z.string().nullable(),
  service: z.number().nullable(),
  serviceOwnClient: z.string().nullable(),
  serviceSms: z.boolean().nullable(),
  specialization: z.string().nullable(),
  updatedAt: z.string(),
  whitelabel: z.number(),
});
export type User = z.infer<typeof UserSchema>;

export const VehicleSchema = z.object({
  brand: BrandSchema.nullable(),
  brandExtra: z.string().nullable(),
  capacity: z.string().nullable(),
  civ: z.string().nullable(),
  deletedAt: z.string().nullable(),
  externalContactData: z.string().nullable(),
  externalId: z.string(),
  fuel: z.number().nullable(),
  id: z.number(),
  insuranceCompanyName: z.string().nullable(),
  itpExpirationDate: z.string().nullable(),
  itpExpirationOnline: z.boolean(),
  mass: z.string().nullable(),
  mileage: z.number(),
  model: ModelSchema.nullable(),
  modelExtra: z.string().nullable(),
  nextRevisionDate: z.string().nullable(),
  nextRevisionMileage: z.string().nullable(),
  photo: z.string().nullable(),
  power: z.string().nullable(),
  rcaExpirationDate: z.string().nullable(),
  rcaExpirationOnline: z.boolean(),
  rcaFromAutopia: z.boolean(),
  rcaLocked: z.boolean(),
  registrationNumber: z.string(),
  seats: z.string().nullable(),
  type: z.number().nullable(),
  user: UserSchema,
  vignetteExpirationDate: z.string().nullable(),
  vignetteExpirationOnline: z.boolean(),
  vin: z.string(),
  year: z.string().nullable(),
});
export const SimpleVehicleSchema = VehicleSchema.merge(
  z.object({
    brand: z.number().nullable(),
    model: z.number().nullable(),
    user: z.number().nullable(),
  })
);
export type Vehicle = z.infer<typeof VehicleSchema>;

export const VehicleSearchSchema = z.object({
  name: z.string().optional(),
  phone: z.string().optional(),
  email: z.string().optional(),
  brand: z.string().optional(),
  capacity: z.string().optional(),
  fuel: z.string().optional(),
  model: z.string().optional(),
  power: z.string().optional(),
  registrationNumber: z.string().optional(),
  vin: z.string().optional(),
  year: z.string().optional(),
});
export type VehicleSearchResponse = z.infer<typeof VehicleSearchSchema>;

// TODO: Add the rest of the fields
export const CostItemSchema = z.object({
  id: z.number(),
});
export type CostItem = z.infer<typeof CostItemSchema>;

export const ServiceSchema = z.object({
  acceptanceRate: z.number(),
  active: z.boolean(),
  address: z.string(),
  cover: z.string(),
  createdAt: z.string(),
  creditsCode: z.string(),
  description: z.string(),
  electrica: z.boolean(),
  email: z.string(),
  fbUrl: z.string().nullable(),
  id: z.number(),
  images: z.array(z.string()),
  itp: z.boolean(),
  lat: z.number(),
  lng: z.number(),
  location: z.string(),
  logo: z.string(),
  lunchBreakEnd: z.string(),
  lunchBreakStart: z.string(),
  managerCode: z.string().nullable(),
  mecanica: z.boolean(),
  comparator: z.boolean(),
  name: z.string(),
  phone: z.string(),
  placeId: z.string().nullable(),
  rating: z.number(),
  ratingCount: z.number(),
  smsExternalAppointments: z.boolean(),
  sold: z.number(),
  tuning: z.boolean(),
  updatedAt: z.string(),
  vopsitorie: z.boolean(),
});
export type Service = z.infer<typeof ServiceSchema>;

export const AppointmentSchema = z.object({
  anonymous: z.boolean(),
  confirmedAt: z.string().nullable(),
  createdAt: z.string(),
  date: z.string(),
  duration: z.string(),
  extra: z.object({
    client_name: z.string(),
    client_name_sms: z.string(),
    client_phone: z.string(),
    client_email: z.string(),
    service_user: z.number(),
    garantie: z.boolean(),
    rca: z.boolean(),
    client_phone_sms: z.string(),
  }),
  id: z.number(),
  observations: z.string().nullable(),
  observationsClient: z.string().nullable(),
  promotion: z.number().nullable(),
  service: z.number(),
  serviceRamp: z.number(),
  status: z.string(),
  time: z.string(),
  type: z.string(),
  updatedAt: z.string(),
  user: z.number(),
  vehicle: z.number(),
  vulcanizare: z.boolean(),
});
export type Appointment = z.infer<typeof AppointmentSchema>;

// TODO: Add the rest of the fields
export const PickerAppointmentSchema = z.object({});
export type PickerAppointment = z.infer<typeof PickerAppointmentSchema>;

export enum OPERATIION_STATUS {
  CREATED = 'CREATED',
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  PAID = 'PAID',
  PAUSED = 'PAUSED',
  WAITING_FOR_PARTS = 'WAITING_FOR_PARTS',
}
export enum OPERATIION_TYPE {
  ELECTRICA = 'ELECTRICA',
  ITP = 'ITP',
  MECANICA = 'MECANICA',
  TUNING = 'TUNING',
  VOPSITORIE = 'VOPSITORIE',
}
export const OperationSchema = z.object({
  appointment: AppointmentSchema.nullable(),
  costitems: z.array(CostItemSchema),
  createdAt: z.string(),
  creditsConsumed: z.number().nullable(),
  documents: z.string().nullable(),
  endBilledTime: z.string(),
  endedAt: z.string().nullable(),
  expirationDateITP: z.string().nullable(),
  externalId: z.string(),
  feedbackGiven: z.boolean(),
  futureObservations: z.string().nullable(),
  futureObservationsReadAt: z.string().nullable(),
  id: z.number(),
  km: z.number().nullable(),
  mechanic: UserSchema,
  moneyBefore: z.number().nullable(),
  nextRevision: z.string().nullable(),
  notifyOffer: z.number(),
  outputObservations: z.string().nullable(),
  pickerAppointment: PickerAppointmentSchema.nullable(),
  refunded: z.boolean(),
  sccr: z.number(),
  service: ServiceSchema,
  smcr: z.number(),
  startBilledTime: z.string(),
  startedAt: z.string().nullable(),
  status: z.nativeEnum(OPERATIION_STATUS),
  type: z.nativeEnum(OPERATIION_TYPE),
  updatedAt: z.string(),
  user: z.number(),
  vehicle: SimpleVehicleSchema,
});
export const OperationsResponseSchema = z.array(OperationSchema);
export type Operation = z.infer<typeof OperationSchema>;

export const VehicleDetailsSchema = z.object({
  registrationNumber: z.string().nullish().optional(),
  brand: z.string().nullish().optional(),
  model: z.string().nullish().optional(),
  VIN: z.string().nullish().optional(),
  capacity: z.string().nullish().optional(),
  power: z.string().nullish().optional(),
  fuel: z.string().nullish().optional(),
  year: z.string().nullish().optional(),
});
export type VehicleDetails = z.infer<typeof VehicleDetailsSchema>;
export const ClientDetailsSchema = z.object({
  phone: z.string().nullish().optional(),
  email: z.string().nullish().optional(),
  name: z.string().nullish().optional(),
});
export type ClientDetails = z.infer<typeof ClientDetailsSchema>;

export const SupplierSchema = z.enum([
  'intercars',
  'eoriginal',
  'euroestcar',
  'unix',
  'aicat',
  'webcat-solutions',
  'materom',
]);
export const SuppliersSchema = z.array(SupplierSchema);
export type Supplier = z.infer<typeof SupplierSchema>;

export const PriceSchema = z.object({
  novat: z.number().nullish().optional(),
  withvat: z.number().nullish().optional(),
  novatClient: z.number().nullish().optional(),
  withvatClient: z.number().nullish().optional(),
});
export const PartSchema = z.object({
  supplier: SupplierSchema,
  name: z.string(),
  description: z.string().nullish().optional(),
  tehnics: z.string().nullish().optional(),
  delivery: z.string().nullish().optional(),
  deliveryText: z.string().nullish().optional(),
  photo: z.string().nullish().optional(),
  photoLarge: z.string().nullish().optional(),
  stoc: z.string().or(z.boolean()).nullish().optional(),
  stocText: z.string().nullish().optional(),
  price: PriceSchema,
  brand: z.string().nullish().optional(),
  oe: z.string().or(z.boolean()).nullish().optional(),
  buy: z.object({
    description: z.string().nullish().optional(),
    id: z.string().nullish().optional(),
    index: z.number().nullish().optional(),
    name: z.string().nullish().optional(),
    novat: z.string().nullish().optional(),
    novatClient: z.string().nullish().optional(),
    oe: z.string().or(z.boolean()).nullish().optional(),
    unix: z.string().nullish().optional(),
    withvat: z.string().nullish().optional(),
    withvatClient: z.string().nullish().optional(),
    article: z.any().optional(),
  }),
});
export const PartsSchema = z.array(PartSchema);
export type Part = z.infer<typeof PartSchema>;

export const DiscountSchema = z
  .object({
    type: z.enum(['percentage', 'fixed']).nullable(),
    value: z.number().nullable(),
  })
  .nullable();
export type Discount = z.infer<typeof DiscountSchema>;

export const CartItemSchema = z.object({
  createdAt: z.string(),
  updatedAt: z.string(),
  id: z.number(),
  supplier: SupplierSchema,
  search: z.string(),
  quantity: z.number(),
  item: PartSchema,
  discount: DiscountSchema.optional(),
  finalPrice: z.number().nullish().optional(),
  ordered: z.boolean().nullable(),
  status: z.enum(['ORDERED', 'FAILED']).nullish().optional(),
});
export type CartItem = z.infer<typeof CartItemSchema>;

export enum CustomItemType {
  ITEM = 'item',
  WORKMANSHIP = 'workmanship',
  //to be removed
  MANOPERA = 'manopera',
}
export const CartCustomItemSchema = z.object({
  createdAt: z.string(),
  updatedAt: z.string(),
  id: z.number(),
  name: z.string(),
  type: z.nativeEnum(CustomItemType),
  quantity: z.number().nullish().optional(),
  novat: z.number().nullish().optional(),
  withvat: z.number().nullish().optional(),
  novatClient: z.number().nullish().optional(),
  withvatClient: z.number().nullish().optional(),
  duration: z.number().nullish().optional(),
  finalPrice: z.number().nullable().optional(),
  discount: DiscountSchema.nullable().optional(),
});

export type CartCustomItem = z.infer<typeof CartCustomItemSchema>;

export const CartSchema = z.object({
  id: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  isOrder: z.boolean().default(false),
  operationId: z.string().nullable(),
  appointment: AppointmentSchema.nullable(),
  clientDetails: ClientDetailsSchema,
  externalId: z.string(),
  isFinal: z.boolean().default(false),
  service: z.number().nullable(),
  user: z.number().nullable(),
  vehicleDetails: VehicleDetailsSchema,
  items: z.array(CartItemSchema).optional(),
  customItems: z.array(CartCustomItemSchema).optional(),
  workmanship: z.number().nullable(),
  commercial: z.number().nullable(),
  status: z.enum(['CREATED', 'PENDING', 'ACCEPTED', 'REJECTED', 'ORDERED']),
  oldstatus: z.enum(['CREATED', 'PENDING', 'ACCEPTED', 'REJECTED', 'ORDERED']).nullable().optional(),
  mentions: z.string().nullable().optional(),
  observations: z.string().nullable().optional(),
  terms: z.string().nullable().optional(),
});
export type Cart = z.infer<typeof CartSchema>;

export const PartsProviderCredentialsSchema = z.object({
  id: z.number(),
  supplier: SupplierSchema,
  username: z.string(),
  password: z.string(),
});
export type PartsProviderCredentials = z.infer<typeof PartsProviderCredentialsSchema>;

export const LocalStorageBrandSchema = z.object({
  id: z.number(),
  name: z.string(),
  image: z.string().nullable(),
});
export const LocalStorageBrandsSchema = z.array(LocalStorageBrandSchema);
export type LocalStorageBrandType = z.infer<typeof LocalStorageBrandSchema>;
