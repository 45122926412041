export const SET_SEARCH_APPOINTMENT = 'SET_SEARCH_APPOINTMENT';
export const SET_SEARCH_VEHICLE = 'SET_SEARCH_VEHICLE';
export const SET_SERVICE_RAMP_FILTER = 'SET_SERVICE_RAMP_FILTER';
export const SET_PICKER_RAMP_FILTER = 'SET_PICKER_RAMP_FILTER';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export const initialState = {
  appointment: null,
  vehicle: null,
  serviceRampFilter: null,
  pickerRampFilter: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_APPOINTMENT:
      return Object.assign({}, state, {
        appointment: action.payload,
        vehicle: null,
      });
    case SET_SEARCH_VEHICLE:
      return Object.assign({}, state, {
        appointment: null,
        vehicle: action.payload,
      });
    case SET_SERVICE_RAMP_FILTER:
      return Object.assign({}, state, {
        serviceRampFilter: action.payload,
      });
    case SET_PICKER_RAMP_FILTER:
      return Object.assign({}, state, {
        pickerRampFilter: action.payload,
      });
    case CLEAR_SEARCH:
      return initialState;
    default:
      return state;
  }
};

export const setSearchAppointment = (appointment) => {
  return {
    type: SET_SEARCH_APPOINTMENT,
    payload: appointment,
  };
};

export const setSearchVehicle = (vehicle) => {
  return {
    type: SET_SEARCH_VEHICLE,
    payload: vehicle,
  };
};

export const setServiceRampFilter = (serviceRampFilter) => {
  return {
    type: SET_SERVICE_RAMP_FILTER,
    payload: serviceRampFilter,
  };
};

export const setPickerRampFilter = (pickerRampFilter) => {
  return {
    type: SET_PICKER_RAMP_FILTER,
    payload: pickerRampFilter,
  };
};

export const clearSearch = () => {
  return {
    type: CLEAR_SEARCH,
  };
};

/**
 * Selectors
 */
// Service ramp filter selector
export const serviceRampFilterSelector = (state) => state.search.serviceRampFilter;
export const pickerRampFilterSelector = (state) => state.search.pickerRampFilter;
