import { login as apiLogin, addDefaultComparatorSettings } from '../api';

import { getServiceRequest, clearService } from './service';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_MGC_SET_TIME = 'SET_MGC_SET_TIME';

export const initialState = {
  authenticated: false,
  token: null,
  userId: null,
  email: null,
  fname: null,
  lname: null,
  isLoading: false,
  isError: false,
  error: null,
  service: null,
  mgc: null,
  mgcSetTime: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return Object.assign({}, state, {
        isError: false,
        authenticated: false,
        error: null,
        isLoading: true,
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        authenticated: true,
        token: action.payload.token,
        userId: action.payload.user.id,
        email: action.payload.user.email,
        fname: action.payload.user.fname,
        lname: action.payload.user.lname,
        service: action.payload.user.service,
        mgc: action.payload.user.mgc,
        comparatorSettings: action.payload.user.comparatorSettings,
      });

    case LOGIN_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        isError: true,
        error: action.payload,
      });
    case LOGOUT:
      return initialState;
    case REFRESH_TOKEN:
      return Object.assign({}, state, {
        token: action.payload.token,
        userId: action.payload.user.id,
        email: action.payload.user.email,
        fname: action.payload.user.fname,
        lname: action.payload.user.lname,
        service: action.payload.user.service,
        mgc: action.payload.user.mgc,
        authenticated: true,
        comparatorSettings: action.payload.user.comparatorSettings,
      });
    case SET_TOKEN:
      return Object.assign({}, state, {
        token: action.payload.token,
        authenticated: false,
      });
    case SET_MGC_SET_TIME:
      return Object.assign({}, state, {
        mgcSetTime: new Date(),
      });
    default:
      return state;
  }
};

export const login = (email, password) => {
  return (dispatch) => {
    dispatch({ type: LOGIN });
    return apiLogin({ email, password })
      .then((response) => {
        if (response.data.user.userGroups.indexOf('SERVICE') > -1) {
          if (!response.data.user.comparatorSettings) {
            // If user does not have comparator settings, add default ones
            addDefaultComparatorSettings(response.data.user.id).then((settings) => {
              const newData = { ...response.data, user: { ...response.data.user, comparatorSettings: settings.data } };
              dispatch(loginSuccess(newData));
              dispatch(getServiceRequest(response.data.user.service));
            });
          } else {
            dispatch(loginSuccess(response.data));
            dispatch(getServiceRequest(response.data.user.service));
          }
        } else dispatch(loginError('INVALID_CREDENTIALS'));
      })
      .catch((error) => {
        if (error.response)
          switch (error.response.status) {
            case 400:
              dispatch(loginError('BAD_REQUEST'));
              break;
            case 403:
              dispatch(loginError('INVALID_CREDENTIALS'));
              break;
            default:
              dispatch(loginError('SERVER_ERROR'));
          }
        else dispatch(loginError('SERVER_ERROR'));
      });
  };
};

export const loginSuccess = (loginData) => {
  return {
    type: LOGIN_SUCCESS,
    payload: loginData,
  };
};

export const loginError = (error) => {
  return {
    type: LOGIN_ERROR,
    payload: error,
  };
};

export const triggerLogout = () => {
  return (dispatch) => {
    dispatch(logout());
    dispatch(clearService());
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const refreshToken = (data) => {
  return {
    type: REFRESH_TOKEN,
    payload: data,
  };
};

export const setToken = (token) => {
  console.log('SET_TOKEN', token);
  return {
    type: SET_TOKEN,
    payload: { token },
  };
};

export const setMgcSetTime = () => {
  return {
    type: SET_MGC_SET_TIME,
  };
};
