const OPERATION_STATUS = {
  CREATED: 'CREATED',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
  PAUSED: 'PAUSED',
  WAITING_FOR_PARTS: 'WAITING_FOR_PARTS',
  PAID: 'PAID'
};

export default OPERATION_STATUS;
