import { getPickerAppointments } from '../api';
import _ from 'lodash';

export const GET_PICKER_APPOINTMENT_REQUEST = 'GET_PICKER_APPOINTMENT_REQUEST';
export const GET_PICKER_APPOINTMENT_SUCCESS = 'GET_PICKER_APPOINTMENT_SUCCESS';
export const GET_PICKER_APPOINTMENT_ERROR = 'GET_PICKER_APPOINTMENT_ERROR';

export const initialState = {
  isLoading: false,
  error: null,
  pickerAppointments: [],
};

/**
 * Reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PICKER_APPOINTMENT_REQUEST:
      return Object.assign({}, state, {
        error: null,
        isLoading: true,
      });
    case GET_PICKER_APPOINTMENT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        pickerAppointments: action.payload,
      });
    case GET_PICKER_APPOINTMENT_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.payload,
      });
    default:
      return state;
  }
};

/**
 * Action creators
 */
// Fetch picker appointments
export const getPickerAppointmentsRequest = () => {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch({ type: GET_PICKER_APPOINTMENT_REQUEST });
    return getPickerAppointments(auth.service)
      .then((response) => {
        // console.log('Get PickerAppointments response', response.data);
        //dispatch(getPickerAppointmentsSuccess(response.data));
        const sortedData = _.take(_.orderBy(response.data, ['date1'], ['desc']), 1000); //pulled from nothing
        dispatch(getPickerAppointmentsSuccess(sortedData));
      })
      .catch((error) => {
        if (error.response)
          switch (error.response.status) {
            case 400:
              dispatch(getPickerAppointmentsError('BAD_REQUEST'));
              break;
            default:
              dispatch(getPickerAppointmentsError('SERVER_ERROR'));
          }
        else dispatch(getPickerAppointmentsError('SERVER_ERROR'));
      });
  };
};

// PickerAppointments fetched successfully
export const getPickerAppointmentsSuccess = (pickerAppointments: any) => {
  return {
    type: GET_PICKER_APPOINTMENT_SUCCESS,
    payload: pickerAppointments,
  };
};

// Error fetching operations
export const getPickerAppointmentsError = (error) => {
  return {
    type: GET_PICKER_APPOINTMENT_ERROR,
    payload: error,
  };
};

/**
 * Selectors
 */
export const pickerAppointmentsSelector = (state) => state.pickerAppointment.pickerAppointments;
