export const GET_CALENDAR_ZOOM = 'GET_CALENDAR_ZOOM';
export const SET_CALENDAR_ZOOM = 'SET_CALENDAR_ZOOM';

export const initialState = {
  calendarStep: 15,
};

/**
 * Reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CALENDAR_ZOOM:
      return state;
    case SET_CALENDAR_ZOOM:
      return Object.assign({}, state, {
        calendarStep: action.payload,
      });
    default:
      return state;
  }
};

/**
 * Action creators
 */

export const setCalendarZoom = (calendarStep: any) => {
  return {
    type: SET_CALENDAR_ZOOM,
    payload: calendarStep,
  };
};

/**
 * Selectors
 */
export const calendarZoomSelector = (state) => state.calendarZoom;
