import { combineReducers } from 'redux';
import auth from './auth';
import service from './service';
import search from './search';
import operation from './operation';
import blockRamp from './blockRamp';
import vehicle from './vehicle';
import serviceRamp from './serviceRamp';
import pickerRamp from './pickerRamp';
import mechanic from './mechanic';
import calendarZoom from './calendarZoom';
import consilier from './consilier';
import pickerAppointment from './pickerAppointment';

const reducers = combineReducers({
  auth,
  service,
  search,
  operation,
  blockRamp,
  vehicle,
  serviceRamp,
  pickerRamp,
  mechanic,
  calendarZoom,
  consilier,
  pickerAppointment
});

export default reducers;
