// @ts-nocheck
import axios from 'axios';
import config from '../config';
import moment from 'moment';
import { OperationsResponseSchema, VehicleSchema } from 'stores/types';
import { z } from 'zod';

const { BASE_URL, STATICDOC_URL } = config.backend;
const { GOOGLE_GEOCODING_API_KEY, STATICDOCS_API_KEY } = config.application;

/**
 * Authentication
 */

// Sign up
export const signUp = (data) => {
  return axios.post(`${BASE_URL}/auth/sign-up/`, data);
};

// Login
export const login = (data) => {
  return axios.post(`${BASE_URL}/auth/login/service/`, data);
};

// Reset password
export const resetPassword = (data) => {
  return axios.post(`${BASE_URL}/auth/reset-password/`, data);
};

// Change password
export const changePassword = (data) => {
  return axios.put(`${BASE_URL}/auth/change-password/`, data);
};

// Refresh token
export const refreshToken = () => {
  return axios.get(`${BASE_URL}/auth/refresh-token/`);
};

// Check email
export const checkEmail = (data) => {
  return axios.post(`${BASE_URL}/auth/check-email/`, data);
};

/**
 * Services
 */

// Retrieves a service
export const getService = (serviceId) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/`);
};

// Edits a service
export const editService = (serviceId, data) => {
  return axios.put(`${BASE_URL}/services/${serviceId}/`, data);
};

// Retrieves all mechanics
export const getMechanics = (serviceId) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/mechanics/`);
};

// Eanble mechanic
export const enableMechanic = (mechanicId) => {
  return axios.post(`${BASE_URL}/mechanics/${mechanicId}/enable/`);
};

// Disable mechanic
export const disableMechanic = (mechanicId) => {
  return axios.delete(`${BASE_URL}/mechanics/${mechanicId}/`);
};

// Edit mechanic
export const editMechanic = (mechanicId, data) => {
  return axios.put(`${BASE_URL}/mechanics/${mechanicId}/`, data);
};

// Delete mechanic
export const deleteMechanic = (mechanicId) => {
  return axios.delete(`${BASE_URL}/services/mechanics/${mechanicId}/`);
};

// Retrieves all service ramps
export const getServiceRamps = (serviceId) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/service-ramps/`);
};

// Creates new service ramp
export const addServiceRamp = (serviceId, data) => {
  return axios.post(`${BASE_URL}/services/${serviceId}/service-ramps/`, data);
};

// Edit service ramp
export const editServiceRamp = (serviceId, serviceRampId, data) => {
  return axios.put(`${BASE_URL}/services/${serviceId}/service-ramps/${serviceRampId}/`, data);
};

// Delete service ramp
export const deleteServiceRamp = (serviceId, serviceRampId) => {
  return axios.delete(`${BASE_URL}/services/${serviceId}/service-ramps/${serviceRampId}/`);
};

/**
 * Picker ramps
 */
// Retrieves all picker ramps
export const getPickerRamps = (serviceId) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/picker-ramps/`);
};

// Creates new picker ramp
export const addPickerRamp = (serviceId, data) => {
  return axios.post(`${BASE_URL}/services/${serviceId}/picker-ramps/`, data);
};

// Edit picker ramp
export const editPickerRamp = (serviceId, pickerRampId, data) => {
  return axios.put(`${BASE_URL}/services/${serviceId}/picker-ramps/${pickerRampId}/`, data);
};

// Delete picker ramp
export const deletePickerRamp = (serviceId, pickerRampId) => {
  return axios.delete(`${BASE_URL}/services/${serviceId}/picker-ramps/${pickerRampId}/`);
};

/**
 * Search for existing vehicles by external ID
 * @param {*} vehicleEid Vehicle's external ID
 */
export const searchVehicles = (vehicleEid: string) => {
  return axios.get(`${BASE_URL}/vehicles/search/${vehicleEid}`);
};

/**
 * Search for existing vehicles by different stuff
 */
export const searchUsersAndVehiclesByExternalId = (vehicleEid: string) => {
  return axios.get(`${BASE_URL}/usersvehicles/search/eid/${vehicleEid}`);
};
export const searchUsersAndVehiclesByPickerAppointmentEid = (pickerAppointmentEid: string) => {
  return axios.get(`${BASE_URL}/usersvehicles/search/picker-appointment/${pickerAppointmentEid}`);
};
export const searchUsersAndVehiclesByVin = (vin: string) => {
  return axios.get(`${BASE_URL}/usersvehicles/search/vin/${vin}`);
};
export const searchUsersAndVehiclesByPhone = (phone: string) => {
  return axios.get(`${BASE_URL}/usersvehicles/search/phone/${phone}`);
};
export const searchUsersAndVehiclesByRegistrationNumber = (registrationnumber: string) => {
  return axios.get(`${BASE_URL}/usersvehicles/search/registrationnumber/${registrationnumber}`);
};

/**
 * Search for existing vehicles by different stuff for picker appointment quick
 */
export const findVehicle = (search: string) => {
  return axios.post(`${BASE_URL}/usersvehicles/find-vehicle`, { search });
};
export const searchUsersAndVehiclesByExternalIdForPicker = (vehicleEid: string) => {
  return axios.get(`${BASE_URL}/usersvehicles/picker-search/eid/${vehicleEid}`);
};
export const searchUsersAndVehiclesByVinForPicker = (vin: string) => {
  return axios.get(`${BASE_URL}/usersvehicles/picker-search/vin/${vin}`);
};
export const searchUsersAndVehiclesByPhoneForPicker = (phone: string) => {
  return axios.get(`${BASE_URL}/usersvehicles/picker-search/phone/${phone}`);
};
export const searchUsersAndVehiclesByRegistrationNumberForPicker = (registrationnumber: string) => {
  return axios.get(`${BASE_URL}/usersvehicles/picker-search/registrationnumber/${registrationnumber}`);
};

/**
 * Fetches vehicle's extended information
 * @param {*} vehicleId Vehicle's internal ID
 */
export const getVehicleExtendedInformationAxios = (vehicleId: number) => {
  return axios.get(`${BASE_URL}/vehicles/${vehicleId}/extended-info/`);
};
export const getVehicleExtendedInformation = async (vehicleId: number) => {
  try {
    const response = await axios.get(`${BASE_URL}/vehicles/${vehicleId}/extended-info/`);
    const vehicle = VehicleSchema.parse(response.data);

    return vehicle;
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error('[getVehicleExtendedInformation] Error validating vehicle response:', error);
    } else {
      console.error('[getVehicleExtendedInformation] Error getting vehicle:', error);
    }
  }
};

/**
 * Fetches vehicle options
 */
export const getVehicleOptions = () => {
  return axios.get(`${BASE_URL}/vehicles/options/`);
};

/**
 * Special sign up for service mechanics
 */
export const signUpMechanicNoEmail = (data) => {
  return axios.post(`${BASE_URL}/auth/sign-up/mechanic`, data);
};

/**
 *
 * Appointments
 *
 */
export const getAppointments = (serviceId) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/appointments/`);
};

export const getOperations = (serviceId) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/operations/`);
};

export const getPickerAppointments = (serviceId) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/picker-appointments/`);
};

/**
 * Retrieves operations for a given vehicle
 * @param {number} vehicleId Vehicle internal ID
 */
export const getVehicleOperationsAxios = (vehicleId: number) => {
  return axios.get(`${BASE_URL}/vehicles/${vehicleId}/operations/`);
};
export const getVehicleOperations = async (vehicleId: number) => {
  try {
    const response = await axios.get(`${BASE_URL}/vehicles/${vehicleId}/operations/`);
    const operations = OperationsResponseSchema.parse(response.data);
    return operations;
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error('[getVehicleOperations] Error validating vehicle operations response:', error);
    } else {
      console.error('[getVehicleOperations] Error getting vehicle operations:', error);
    }
  }
};

/**
 * Finishes an operation
 * @param {*} serviceId Service internal ID
 * @param {*} operationId Operation internal iD
 */
export const finishOperation = (serviceId: number, operationId: number, data) => {
  return axios.put(`${BASE_URL}/services/${serviceId}/operations/${operationId}/finish/`, data);
};

/**
 * Mark an operation as PAID
 * @param {*} serviceId Service internal ID
 * @param {*} operationId Operation internal iD
 */
export const markOperationAsPaidCash = (serviceId: number, operationId: number) => {
  return axios.put(`${BASE_URL}/services/${serviceId}/operations/${operationId}/pay/cash/`);
};

/**
 * Update operation's cost items
 * @param {number} operationId Operation internal ID
 * @param {Array} data New cost items
 */
export const updateOperationCostItems = (operationId: number, data: Array<object>) => {
  return axios.put(`${BASE_URL}/operations/${operationId}/cost-items/`, data);
};

/**
 * Retrieves an operation by it's appointment ID
 * @param {number} appointmentId Appointment internal ID
 */
export const getOperationByAppointment = (appointmentId: number) => {
  return axios.get(`${BASE_URL}/operations-by-appointment/${appointmentId}/`);
};

/**
 * Retrieves an operation and its appointment from a picker appointment
 * @param {number} pickerAppointmentId Picker Appointment internal ID
 */
export const getOperationAndAppointmentByPickerAppointment = (pickerAppointmentId: number) => {
  return axios.get(`${BASE_URL}/operation-and-appointment-by-picker-appointment/${pickerAppointmentId}/`);
};

/**
 * Update existing appointment
 * @param {number} appointmentId Appointment internal ID
 * @param {object} data Appointment update body
 */
export const updateAppointment = (appointmentId: number, data: object) => {
  return axios.patch(`${BASE_URL}/appointments/${appointmentId}`, data);
};

/**
 * Update picker appointment
 * @param {number} pickerAppointmentId Picker Appointment internal ID
 * @param {object} data Picker Appointment update body
 */
export const updatePickerAppointment = (pickerAppointmentId: number, data: object) => {
  return axios.patch(`${BASE_URL}/picker-appointments/${pickerAppointmentId}/`, data);
};

/**
 * Update existing operation
 * @param {number} operationId Operation internal ID
 * @param {object} data Operation update body
 */
export const updateOperation = (operationId: number, data: object) => {
  return axios.patch(`${BASE_URL}/operations/${operationId}`, data);
};

/**
 * Start existing operation
 * @param {number} serviceId Service internal ID
 * @param {number} operationId Operation internal ID
 */
export const startOperation = (serviceId: number, operationId: number) => {
  return axios.put(`${BASE_URL}/services/${serviceId}/operations/${operationId}/start/`);
};

/**
 * Pause existing operation
 * @param {number} serviceId Service internal ID
 * @param {number} operationId Operation internal ID
 */
export const pauseOperation = (serviceId: number, operationId: number) => {
  return axios.put(`${BASE_URL}/services/${serviceId}/operations/${operationId}/pause/`);
};

/**
 * Resume existing operation
 * @param {number} serviceId Service internal ID
 * @param {number} operationId Operation internal ID
 */
export const resumeOperation = (serviceId: number, operationId: number) => {
  return axios.put(`${BASE_URL}/services/${serviceId}/operations/${operationId}/resume/`);
};

/**
 * Wait for parts existing operation
 * @param {number} serviceId Service internal ID
 * @param {number} operationId Operation internal ID
 */
export const waitForPartsOperation = (serviceId: number, operationId: number) => {
  return axios.put(`${BASE_URL}/services/${serviceId}/operations/${operationId}/wait-for-parts/`);
};

/**
 * Mark TAKEN existing picker appointment
 * @param {number} serviceId Service internal ID
 * @param {number} pickerAppointmentId Picker Appointment internal ID
 */
export const takenPickerAppointment = (serviceId: number, pickerAppointmentId: number) => {
  return axios.put(`${BASE_URL}/services/${serviceId}/picker-appointments/${pickerAppointmentId}/taken/`);
};
/**
 * Mark DELIVERED existing picker appointment
 * @param {number} serviceId Service internal ID
 * @param {number} pickerAppointmentId Picker Appointment internal ID
 */
export const deliveredPickerAppointment = (serviceId: number, pickerAppointmentId: number) => {
  return axios.put(`${BASE_URL}/services/${serviceId}/picker-appointments/${pickerAppointmentId}/delivered/`);
};

/**
 * Cancels operations
 * @param {number} operationId Operation internal ID
 */
export const cancelOperation = (operationId: number) => {
  return axios.delete(`${BASE_URL}/operations/${operationId}/`);
};

/**
 * Cancels appointments
 * @param {number} appointmentId Appointment internal ID
 */
export const cancelAppointment = (appointmentId: number) => {
  return axios.delete(`${BASE_URL}/appointments/${appointmentId}/`);
};

/**
 * Confirms an appointment
 * @param {number} serviceId Service internal ID
 * @param {number} appointmentId Appointment internal ID
 * @param {array} body Body object
 */
export const confirmAppointment = (serviceId: number, appointmentId: number, body: any) =>
  axios.put(`${BASE_URL}/services/${serviceId}/appointments/${appointmentId}/confirm/`, body);

export const rejectAppointment = (serviceId, appointmentId) =>
  axios.put(`${BASE_URL}/services/${serviceId}/appointments/${appointmentId}/reject/`);

export const fulfillAppointment = (vehicleId, data) =>
  axios.post(`${BASE_URL}/vehicles/${vehicleId}/operations/`, data);

/**
 * Schedule a quick appointment
 * @param {*} serviceId Service internal ID
 * @param {*} data Appointment data body
 */
export const scheduleQuickAppointment = (serviceId, data) =>
  axios.post(`${BASE_URL}/services/${serviceId}/quick-appointment/`, data);

/**
 * Confirms an appointment
 * @param {number} serviceId Service internal ID
 * @param {number} pickerAppointmentId picker Appointment internal ID
 * @param {array} body Body object
 */
export const confirmPickerAppointment = (serviceId: number, pickerAppointmentId: number, body: any) =>
  axios.put(`${BASE_URL}/services/${serviceId}/picker-appointments/${pickerAppointmentId}/confirm/`, body);

export const rejectPickerAppointment = (serviceId, pickerAppointmentId) =>
  axios.put(`${BASE_URL}/services/${serviceId}/picker-appointments/${pickerAppointmentId}/reject/`);

/**
 * Schedule a quick picker appointment
 * @param {*} serviceId Service internal ID
 * @param {*} data Appointment data body
 */
export const scheduleQuickPickerAppointment = (serviceId, data) =>
  axios.post(`${BASE_URL}/services/${serviceId}/quick-picker-appointment/`, data);

/**
 * Moves an appointment
 * @param {*} serviceId
 * @param {*} appointmentId
 * @param {*} start
 * @param {*} end
 * @param {*} serviceRampId
 */
export const moveAppointment = (serviceId, appointmentId, start, end, serviceRampId: number, silent) =>
  axios.put(`${BASE_URL}/services/${serviceId}/appointments/${appointmentId}/move/`, {
    start,
    end,
    serviceRampId,
    silent,
  });

export const movePickerAppointment = (
  serviceId,
  pickerAppointmentId,
  start,
  end,
  pickerRampId: number,
  silent,
  isFirst
) =>
  axios.put(`${BASE_URL}/services/${serviceId}/picker-appointments/${pickerAppointmentId}/move/`, {
    start,
    end,
    pickerRampId,
    silent,
    isFirst,
  });

/**
 * Retrieves the first free timeslot for a specific type
 */
export const getFirstFreeTimeslot = (serviceId: number, type: string, hours: number, minutes: number) =>
  axios.get(`${BASE_URL}/services/${serviceId}/appointments/first-free-timeslot/${type}/${hours}/${minutes}/`);

/**
 * Retrieves the first free timeslot for a specific type
 */
export const getFirstFreeTimeslotPicker = (serviceId: number, hours: number, minutes: number) =>
  axios.get(`${BASE_URL}/services/${serviceId}/picker-appointments/first-free-timeslot/${hours}/${minutes}/`);

/**
 * Notifications
 */

// Retrieves all notifications
export const getServiceNotifications = (serviceId) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/notifications/`);
};

// Reads notifications
export const readServiceNotifications = (serviceId, data) => {
  return axios.put(`${BASE_URL}/services/${serviceId}/notifications/`, data);
};

/**
 * Geocoding API
 */
export const geocode = (address) => {
  return axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GOOGLE_GEOCODING_API_KEY}`,
    {
      headers: undefined,
    }
  );
};

/**
 * Start new promotion campaign
 */
export const startNewPromotion = (data: any): Promise => {
  return axios.post(`${BASE_URL}/services/start-new-promotion/`, data);
};

/**
 * Edit an existing promotion campaign
 */
export const modifyPromotion = (data: any, offerId: number): Promise => {
  return axios.post(`${BASE_URL}/services/my-offers/${offerId}/`, data);
};

/**
 * Deletes an existing promotion campaign
 */
export const deletePromotion = (offerId: number): Promise => {
  return axios.delete(`${BASE_URL}/services/my-offers/${offerId}/`);
};

/**
 * Retrieves my service offers
 */
export const getOffers = (): Promise => {
  return axios.get(`${BASE_URL}/services/my-offers/`);
};

/**
 * Statistics
 */

// box 1

// Retrieves all money influx for the service
export const getServiceMoneyInflux = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/service/influx/`, { params });
};

// Retrieves efficiency for the service
export const getServiceEfficiency = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/service/efficiency/`, { params });
};

// Retrieves billed hours for the service
export const getServiceBilledHours = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/service/billed/`, { params });
};

// Retrieves worked hours for the service
export const getServiceWorkedHours = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/service/worked/`, { params });
};

// box 2

// Retrieves occupancy for the service ramps
export const getServiceRampOccupancy = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/ramp/occupancy/`, { params });
};

// Retrieves efficiency for the service ramps
export const getServiceRampEfficiency = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/ramp/efficiency/`, { params });
};

// Retrieves all money influx for the service ramps
export const getServiceRampMoneyInflux = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/ramp/influx/`, { params });
};

//Retrieves rating for the service ramps
export const getServiceRampRating = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/ramp/rating/`, { params });
};

// box 3

//Retrieves contribution for the service mechanics
export const getServiceMechanicContribution = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/mechanic/contribution/`, { params });
};

// Retrieves worked hours for the service mechanics
export const getServiceMechanicWorkedHours = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/mechanic/worked/`, { params });
};

// Retrieves efficiency for the service mechanics
export const getServiceMechanicEfficiency = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/mechanic/efficiency/`, { params });
};

//Retrieves rating for the service mechanics
export const getServiceMechanicRating = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/mechanic/rating/`, { params });
};

// box 4

// Retrieves promotions for the service
export const getServicePromotions = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/promotions/`, {
    params,
  });
};

export const getServiceMobile = (serviceId) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/promotions/mobile/`);
};

// box 5

// Retrieves summary for the service mechanics
export const getServiceMechanicSummary = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/mechanic/summary/`, {
    params,
  });
};

// box 6

//Retrieves contribution for the service mechanics
export const getServiceSpecialIncome = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/special/income/`, { params });
};

// Retrieves worked hours for the service mechanics
export const getServiceSpecialAcceptance = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/special/acceptance/`, { params });
};

// Retrieves efficiency for the service mechanics
export const getServiceSpecialResponse = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/special/response/`, { params });
};

//Retrieves rating for the service mechanics
export const getServiceSpecialPrice = (serviceId, params) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/statistics/special/price/`, { params });
};

export const getFeedbacks = (): Promise => {
  return axios.get(`${BASE_URL}/services/feedbacks/`);
};

// Checks if manager code is ok
export const verifyManagerCode = (serviceId, data) => {
  return axios.post(`${BASE_URL}/services/${serviceId}/managercode/`, data);
};

/**
 * Fetches service
 */
export const getSerivce = (serviceId: number): Promise => {
  return axios.get(`${BASE_URL}/services/${serviceId}/`);
};

/**
 * Search vehicles/operations
 */
export const searchVehiclesAndOperations = (term: string, singularOperation = false): Promise => {
  return axios.get(`${BASE_URL}/services/search/`, {
    params: { q: term, singularOperation },
  });
};

/**
 * REAL TIME CHAT
 */

/**
 * Fetches last chat messages
 */
export const getLastChatMessages = () => {
  return axios.get(`${BASE_URL}/chats/last/`);
};

/**
 * Fetches chat room entirely
 */
export const getChatRoom = (chatRoomId: number) => {
  return axios.get(`${BASE_URL}/chats/${chatRoomId}/`);
};

/**
 * Send chat message
 */
export const sendChatMessage = (chatRoomId: number, message?: string, document?: string) => {
  return axios.post(`${BASE_URL}/chats/${chatRoomId}/`, {
    message,
    document,
  });
};

/**
 * Delete chat message
 */
export const deleteChatMessage = (messageId: number) => {
  return axios.delete(`${BASE_URL}/chats/message/${messageId}/`);
};

/**
 * Fetches number of unread chats
 */
export const getChatUnreadCount = () => {
  return axios.get(`${BASE_URL}/chats/unreadcount/`);
};

/**
 * Fetches finished operations
 */
export const getFinishedOperations = (startDate: Date, endDate: Date): Promise => {
  return axios.get(
    `${BASE_URL}/services/finished-operations/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format(
      'YYYY-MM-DD'
    )}/`
  );
};

/**
 * Send support message
 */
export const sendSupportMessage = (type?: string, message?: string) => {
  return axios.post(`${BASE_URL}/tech-support/`, {
    type,
    message,
  });
};

/**
 * Send chat feedback
 */
export const sendChatFeedback = (feedbackId: number, message?: string) => {
  return axios.post(`${BASE_URL}/feedback/${feedbackId}/`, {
    message,
  });
};

/**
 * Get chat feedback
 */
export const getChatFeedback = () => {
  return axios.get(`${BASE_URL}/feedback/`);
};

/**
 * Get chat feedback
 */
export const deleteChatFeedback = (feedbackId: number) => {
  return axios.delete(`${BASE_URL}/feedback/${feedbackId}/`);
};

//
//
//  STATIC DOCUMENTS
//
//

/**
 * Get static document collection
 */
export const getStaticDocumentCollection = (collectionName: string) => {
  return axios.get(`${STATICDOC_URL}/collections/get/${collectionName}?token=${STATICDOCS_API_KEY}`);
};

//
//
// PAYMENT
//
//

/**
 * Get order status
 */
export const getOrderStatus = (orderId: string) => {
  return axios.get(`${BASE_URL}/orders/${orderId}/`);
};

//
//
// INVOICES
//
//

/**
 * Get invoices
 */
export const getInvoices = () => {
  return axios.get(`${BASE_URL}/invoices/`);
};

/**
 * Download invoice
 */
export const downloadInvoice = (invoiceId) => {
  return axios.get(`${BASE_URL}/invoices/${invoiceId}/`, {
    responseType: 'blob',
  });
};

//
//
// CREDITS CODE
//
//

/**
 * Change credits code associated with service
 */
export const changeCreditsCode = (creditsCode) => {
  return axios.post(`${BASE_URL}/voucher/service/setcode/`, {
    creditsCode,
  });
};

/**
 * Change credits code associated with service
 */
export const getOwnClientsVoucher = () => {
  return axios.get(`${BASE_URL}/voucherdata/service/`);
};

/**
 * Request money before
 */
export const requestMoneyBefore = (operationId, sum) => {
  return axios.post(`${BASE_URL}/moneybefore/request/${operationId}/`, {
    sum,
  });
};

/**
 * Mark money before as paid cash by the client
 */
export const markMoneyBeforeAsPaidCash = (operationId: number) => {
  return axios.put(`${BASE_URL}/moneybefore/pay/cash/${operationId}/`);
};

/**
 * Add a block ramp "appointment"
 */
export const blockRamp = (serviceRampId, startTime, endTime) => {
  return axios.post(`${BASE_URL}/service/service-ramps/block/${serviceRampId}/`, { startTime, endTime });
};
/**
 * Get all block ramp "appointment"
 */
export const getBlockRamps = () => {
  return axios.get(`${BASE_URL}/service/service-ramps/block/`);
};

/**
 * Delete block ramp "appointment"
 */
export const deleteBlockRamp = (blockRampId) => {
  return axios.delete(`${BASE_URL}/service/service-ramps/block/${blockRampId}/`);
};

/**
 * Move block ramp "appointment"
 */
export const moveBlockRamp = (blockRampId, startTime, endTime) => {
  return axios.put(`${BASE_URL}/service/service-ramps/block/${blockRampId}/move/`, { startTime, endTime });
};

/**
 * Make service ramp unavailable
 */
export const unavailableRamp = (serviceRampId) => {
  return axios.put(`${BASE_URL}/service/service-ramps/unavailable/${serviceRampId}/`);
};

/**
 * Make service ramp available
 */
export const availableRamp = (serviceRampId) => {
  return axios.put(`${BASE_URL}/service/service-ramps/available/${serviceRampId}/`);
};

/**
 * Make service ramp unavailable anonymous
 */
export const unavailableRampAnonymous = (serviceRampId) => {
  return axios.put(`${BASE_URL}/service/service-ramps/anonymous/unavailable/${serviceRampId}/`);
};

/**
 * Make service ramp available anonymous
 */
export const availableRampAnonymous = (serviceRampId) => {
  return axios.put(`${BASE_URL}/service/service-ramps/anonymous/available/${serviceRampId}/`);
};

/**
 * Make service picker ramp unavailable
 */
export const unavailablePickerRamp = (pickerRampId) => {
  return axios.put(`${BASE_URL}/service/picker-ramps/unavailable/${pickerRampId}/`);
};

/**
 * Make service picker ramp available
 */
export const availablePickerRamp = (pickerRampId) => {
  return axios.put(`${BASE_URL}/service/picker-ramps/available/${pickerRampId}/`);
};

/**
 * Brand model and vin added after creating operation
 */
export const updateAppointmentAddBrandModelVin = (appointmentId: number, data: object) => {
  return axios.patch(`${BASE_URL}/appointments/afteradd/${appointmentId}/all/`, data);
};

export const updateOperationCostItemsFuture = (operationId: number, data: Array<object>) => {
  return axios.put(`${BASE_URL}/operations/${operationId}/cost-items-future/`, data);
};

export const updateOperationDocuments = (operationId: number, data) => {
  return axios.put(`${BASE_URL}/operations/${operationId}/documents/`, data);
};

// Retrieves all consiliers
export const getConsiliers = (serviceId) => {
  return axios.get(`${BASE_URL}/services/${serviceId}/consiliers/`);
};

/**
 * Send search parts
 */
export const searchParts = (search?: string, supplier: string, cancelToken) => {
  return axios.post(
    `${BASE_URL}/parts/search/`,
    {
      search,
      supplier,
    },
    {
      cancelToken,
    }
  );
};

export const buyPart = (search: string, supplier: string, item, observations: string, quantity: string) => {
  return axios.post(`${BASE_URL}/parts/buy/`, {
    search,
    supplier,
    item,
    observations,
    quantity,
  });
};

/**
 * Get all Brands
 */

export const getAllBrands = () => {
  return axios.get(`${BASE_URL}/parts/get-brands`);
};

/**
 * Get search parts suppliers
 */
export const getSuppliers = () => {
  return axios.get(`${BASE_URL}/parts/suppliers/`);
};

/**
 * Add to parts cart
 */
export const addToPartsCart = (search: string, item) => {
  return axios.post(`${BASE_URL}/parts/addcart/`, {
    search,
    item,
  });
};
/**
 * Delete from parts cart
 */
export const deleteFromPartsCart = (itemId) => {
  return axios.post(`${BASE_URL}/parts/deleteCart/`, {
    itemId,
  });
};
/**
 * Get cart
 */
export const getCartDetails = (cartId) => {
  return axios.get(`${BASE_URL}/parts/cart/${cartId}`);
};
/**
 * Get offer data
 */
export const getOfferData = (token) => {
  return axios.post(`${BASE_URL}/parts/get-offer-data`, {
    token,
  });
};
/**
 * Accept/reject offer
 */
export const changeOfferStatusRequest = (token, status) => {
  return axios.post(`${BASE_URL}/parts/cart/change-status`, {
    token,
    status,
  });
};
/**
 * Tutorials
 */
export const getTutorials = () => {
  return axios.get(`${BASE_URL}/tutorials/`);
};

/**
 * External database pull costitems
 */
export const pullItemsFromExternalDatabase = (operationId: number) => {
  return axios.get(`${BASE_URL}/external/costitems/${operationId}/`);
};

/**
 * Schedule anonymous appointment
 */
export const scheduleAnonymousAppointmentGetTypes = (serviceId) => {
  return axios.get(`${BASE_URL}/anon/services/${serviceId}/types/`);
};
export const scheduleAnonymousAppointmentGetHours = (serviceId, operationType, selectedDate) => {
  return axios.get(`${BASE_URL}/anon/services/${serviceId}/hours/${operationType}/${selectedDate}/`);
};

export const scheduleAnonymousAppointmentGetFirstAvailableInterval = (serviceId, operationType) => {
  return axios.get(`${BASE_URL}/anon/services/${serviceId}/first-available-interval/${operationType}/${1}/`);
};

export const scheduleAnonymousAppointment = (serviceId, data) => {
  return axios.post(`${BASE_URL}/anon/services/${serviceId}/`, data);
};

/**
 * Notify offer
 */
export const notifyOffer = (operationId: number) => {
  return axios.post(`${BASE_URL}/operations/${operationId}/notify-offer/`);
};

/**
 * User comparator settings
 */
export const addDefaultComparatorSettings = (userId: number) => {
  return axios.post(`${BASE_URL}/users/${userId}/comparator-settings/`);
};
export const updateDefaultComparatorSettings = (
  userId: number,
  body: {
    defaultComercial?: number;
    defaultWorkmanship?: number;
    defaultShowVatPrice?: boolean;
    defaultShowClientPrice?: boolean;
  }
) => {
  return axios.put(`${BASE_URL}/users/${userId}/comparator-settings/`, body);
};
