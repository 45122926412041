import { getService } from '../api';

export const GET_SERVICE_REQUEST = 'GET_SERVICE_REQUEST';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';
export const GET_SERVICE_ERROR = 'GET_SERVICE_ERROR';
export const CLEAR_SERVICE = 'CLEAR_SERVICE';

export const initialState = {
  isLoading: false,
  error: null,
  service: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICE_REQUEST:
      return Object.assign({}, state, {
        error: null,
        isLoading: true,
      });
    case GET_SERVICE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        service: action.payload,
      });
    case GET_SERVICE_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.payload,
      });
    case CLEAR_SERVICE:
      return initialState;
    default:
      return state;
  }
};

export const getServiceRequest = (serviceId) => {
  return (dispatch) => {
    dispatch({ type: GET_SERVICE_REQUEST });
    return getService(serviceId)
      .then((response) => {
        // console.log('Get service response', response.data);
        dispatch(getServiceSuccess(response.data));
      })
      .catch((error) => {
        if (error.response)
          switch (error.response.status) {
            case 400:
              dispatch(getServiceError('BAD_REQUEST'));
              break;
            default:
              dispatch(getServiceError('SERVER_ERROR'));
          }
        else dispatch(getServiceError('SERVER_ERROR'));
      });
  };
};

export const getServiceSuccess = (GET_SERVICEData) => {
  return {
    type: GET_SERVICE_SUCCESS,
    payload: GET_SERVICEData,
  };
};

export const getServiceError = (error) => {
  return {
    type: GET_SERVICE_ERROR,
    payload: error,
  };
};

export const clearService = () => {
  return {
    type: CLEAR_SERVICE,
  };
};
