import 'js-polyfills';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Titled } from 'react-titled';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { PersistGate } from 'redux-persist/integration/react';
import createCompressor from 'redux-persist-transform-compress';
import createEncryptor from 'redux-persist-transform-encrypt';
import reducers from './ducks';
import axios from 'axios';
import NProgress from 'nprogress';
import 'moment/locale/ro';
import asyncComponent from './components/AsyncComponent';
import PaymentMobileFinishedPage from './pages/Payment/Mobile/PaymentMobileFinishedPage';
import PaymentMobileReturnPage from './pages/Payment/Mobile/PaymentMobileReturnPage';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { LOGIN } from './ducks/auth';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { GlobalProviders } from 'stores/globalProviders';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('autopia/autopia');
  setupLogRocketReact(LogRocket);

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}
// Create Custom Sentry Route component
export const SentryRoute = Sentry.withSentryRouting(Route);
const history = createBrowserHistory();
// Pages
const LoginPageWithToken = asyncComponent(() => import('pages/LoginPage/LoginPageWithToken'));
const LoginPage = asyncComponent(() => import('pages/LoginPage/LoginPage'));
const AcceptOffer = asyncComponent(() => import('pages/AcceptOffer/AcceptOffer'));
const DeclineOffer = asyncComponent(() => import('pages/DeclineOffer/DeclineOffer'));

const ForgotPasswordPage = asyncComponent(() => import('pages/ForgotPasswordPage/ForgotPasswordPage'));
const AnonymousAppointmentPage = asyncComponent(() =>
  import('pages/AnonymousAppointmentPage/AnonymousAppointmentPage')
);
const App = asyncComponent(() => import('./components/App/App'));

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: ['*'],
      normalizeDepth: 10,
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0,
});

// Disable NProgress spinner
NProgress.configure({ showSpinner: false });

// Create ReduxPersist Encryptor
const encryptor = createEncryptor({
  secretKey: '7Af7W^uP1ub0A!^RPWaBN2^G@pU*a974&a3h@&*Hc4kk8O*8',
  onError: function (error) {
    // Handle the error.
    console.log(error);
  },
});

// Create ReduxPersist Comperssor
const compressor = createCompressor();

const persistConfig = {
  key: 'root',
  storage,
  transforms: [encryptor],
  blacklist: ['search', 'service'],
};

const logger = createLogger({
  collapsed: (getState, action, logEntry) => !logEntry.error,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (action.type === LOGIN) {
      return {
        ...action,
        password: null,
      };
    }

    return action;
  },
});

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    thunk,
    // logger
  ],
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [sentryReduxEnhancer],
});

const persistor = persistStore(store, { transform: [compressor] });

// Set Authorization token to all axios requests
store.subscribe(() => {
  const token = store.getState()?.auth?.token;
  if (token) axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  else axios.defaults.headers.common['Authorization'] = null;
});

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <GlobalProviders>
        <Titled title={() => 'Autopia'}>
          <Sentry.ErrorBoundary fallback={'An error has occurred'}>
            <BrowserRouter>
              <Switch>
                <SentryRoute path="/auth/:token" component={LoginPageWithToken} />
                <SentryRoute path="/login" component={LoginPage} />
                <SentryRoute path="/acceptoffer" component={AcceptOffer} />
                <SentryRoute path="/declineoffer" component={DeclineOffer} />

                <SentryRoute path="/forgotpassword" component={ForgotPasswordPage} />
                <SentryRoute path="/payment/return/finished/" component={PaymentMobileFinishedPage} />
                <SentryRoute path="/payment/return/" component={PaymentMobileReturnPage} />
                <SentryRoute path="/embed/anonymous-appointment/:serviceId" component={AnonymousAppointmentPage} />
                <SentryRoute path="/" component={App} />
              </Switch>
            </BrowserRouter>
          </Sentry.ErrorBoundary>
        </Titled>
      </GlobalProviders>
    </PersistGate>
  </Provider>
);
const container = document.getElementById('root');
// Create a root.
const root = ReactDOMClient.createRoot(container);
root.render(<Root />);

if (module.hot) {
  module.hot.accept();
}
